import React, { useEffect, useRef, useState } from "react";
import "../streams/";
import {
  Button,
  Flex,
  Layout,
  List,
  Skeleton,
  Modal,
  Input,
  Avatar,
  Space,
  notification,
  Row,
  Card,
  Col,
  Spin,
  Result,
} from "antd";
import TeamsApp from "./teams";
import "./index.css";
import axios from "axios";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";
import logo from "./teams-logo.png";
const { Header, Content, Footer } = Layout;
const App = () => {
  const [MSTeamsUsers, setMSTeamsUsers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchBegin, setIsSearchBegin] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const searchTeamsUsers = (e) => {
    setIsSearching(true);
    setIsSearchBegin(true);

    axios
      .get(
        `https://cjycxmjgdb.execute-api.us-east-1.amazonaws.com/qa/api/users?displayName=${e.target?.value}&tenantId=ff93404f-c1e7-43e5-ab53-a9b9d85c1f98`
      )
      .then((res) => {
        if (res.data?.users?.value) {
          setIsSearching(false);
          setMSTeamsUsers(res.data.users.value);
        }
      });
  };

  const makeTeamsCall = (payload) => {
    try {
      //post message to the parent window
      window.parent.postMessage(
        {
          type: "PRESOVED_QUICK_CONNECT",
          data: payload,
        },
        "*"
      );
      /*  axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "https://p3fusion-teams-dev.p3fusionapps.com/api/teams/callsRequest",
        data: {
          body: {
            from: "connect1",
            to: {
              displayname: payload.displayName,
              id: payload.id,
              tenantId: "ff93404f-c1e7-43e5-ab53-a9b9d85c1f98",
            },
          },
        },
      })
        .then((res) => {
          window.parent.postMessage(
            {
              type: "PRESOVED_QUICK_CONNECT",
              data: payload,
            },
            "*"
          );
          console.log("Response from the API: ", res.data);
          setIsSearching(false);
          setIsSearchBegin(false);
          setShowSuccess(true);

          notification.success({
            message: "Call request to Teams is queued",
            description: "Call request  to Teams is queued",
          });
          //performQuickConnect(res.data);
        })
        .catch((error) => {
          setIsSearching(false);
          setIsSearchBegin(false);
          console.log("Error calling API: ", error);
          notification.error({
            message: "Teams call Queue is full please try again later",
            description: "Teams call Queue is full please try again later",
          });
        }); */
    } catch (error) {
      console.log("Error calling API: ", error);
    }
  };

  return (
    <Layout>
      <Header className="main-header" style={{ color: "#fff" }}>
        <div className="logo">
          <img src={logo} height={52} />
        </div>
        <div className="logo-text">P3Fusion Teams Connect</div>
      </Header>

      <Content className="main">
        <Row justify="center" align="middle">
          <Col span={24}>
            <Card
              size="default"
              bordered
              title={
                <Space>
                  <UserOutlined />
                  <span>Search Users</span>
                </Space>
              }
            >
              <div>
                <div style={{ padding: "15px 0" }}>
                  <Input.Search
                    size="large"
                    placeholder="Search Teams Users"
                    onSearch={searchTeamsUsers}
                    onKeyDown={searchTeamsUsers}
                    enterButton
                    allowClear
                  />
                </div>
                {showSuccess && (
                  <Result
                    status="success"
                    title="Call request to Teams is queued"
                    subTitle="Call request  to Teams is queued"
                    extra={[
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => {
                          setShowSuccess(false);
                          setMSTeamsUsers([]);
                          setIsSearching(false);
                          setIsSearchBegin(false);
                        }}
                      >
                        Ok
                      </Button>,
                    ]}
                  />
                )}
                {!isSearching && isSearchBegin && MSTeamsUsers.length == 0 && (
                  <div className="no-data">
                    <span>No data found</span>
                  </div>
                )}
                {!isSearching && MSTeamsUsers.length > 0 && (
                  <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={MSTeamsUsers}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Button
                            onClick={() => makeTeamsCall(item)} //checkTeamsAvailibility(item)}
                            size="large"
                            block
                            type="default"
                            icon={<PhoneOutlined />}
                          >
                            Connect
                          </Button>,
                        ]}
                      >
                        <Skeleton
                          avatar
                          title={false}
                          loading={item.loading}
                          active
                        >
                          <List.Item.Meta
                            avatar={
                              <Avatar size={70} icon={<UserOutlined />} />
                            }
                            title={`${item.displayName}`}
                            description={
                              item.jobTitle ? (
                                <Space>
                                  <span> {item.jobTitle} </span>
                                  <span> {item.officeLocation} </span>
                                </Space>
                              ) : (
                                false
                              )
                            }
                          />
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                )}
                {isSearching && (
                  <div className="no-data">
                    <Spin size="large">Please wait while we are searching</Spin>
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default App;

/*  

  const performQuickConnect = (item) => {
    var endpoint = connect.Endpoint.byPhoneNumber("+16028994223");
    interaction?.addConnection(endpoint, {
      success: function () {
        console.log("Presolved::connect::contact::addConnection::success::");
        setShowTeamsModal(false);
        notification.success({
          message: "Call transfered to Teams",
          description: "Call transfered to Teams",
        });
      },
      failure: function (err) {
        console.log(
          "Presolved::connect::contact::addConnection::failure::",
          err
        );
      },
    });
  };

  
  const listenIncomingActivities = () => {
    connect.contact(subscribeToContactEvents);
  };
 const subscribeToContactEvents = (contact) => {
    console.log(`Presolved::CCP::Contact::${contact.contactId}`);
    contact.onIncoming(contactIncoming);
    contact.onAccepted(contactAccepted);
    contact.onEnded(contactEnded);
    contact.onConnected(contactConnected);
    contact.onMissed(contactMissed);
    contact.onACW(contactACW);
  };
  const contactIncoming = (contact) => {
    console.log(`Presolved::CCP::Contact::Incoming`);
  };
  const contactAccepted = (contact) => {
    console.log(`Presolved::CCP::Contact::Accepted`);
    setIsCallConnected(true);
    setInteraction(contact);
  };
  const contactEnded = (contact) => {
    console.log(`Presolved::CCP::Contact::Ended`);
    setIsCallConnected(false);
    setInteraction(null);
  };
  const contactConnected = (contact) => {
    console.log(`Presolved::CCP::Contact::Connected`);
    setIsCallConnected(true);
  };
  const contactMissed = (contact) => {
    console.log(`Presolved::CCP::Contact::Missed`);
    setIsCallConnected(false);
    setInteraction(null);
  };
  const contactACW = (contact) => {
    console.log(`Presolved::CCP::Contact::ACW`);
  }; */

/*   useEffect(() => {
    //initiateCCP();
  }, []);

  const initiateCCP = () => {
    const ccpUrl = "https://p3f-test.my.connect.aws/ccp-v2";
    if (ccpInstance.current) {
      const ccps = connect.core.initCCP(ccpInstance.current, {
        ccpUrl,
        loginPopup: false,
        softphone: {
          allowFramedSoftphone: false,
        },
      });
    }

    isCCpInitiated();
    checkExistingContact();
  };

  const checkExistingContact = () => {
    window.connect.agent((agent) => {
      const contacts = agent.getContacts();
      //const contacts = [{ ...mockContact }];
      if (contacts) {
        // Check if contacts is defined
        contacts.forEach((contact, index) => {
          if (contact.getStatus().type == "connected") {
            console.info(
              "Presolved::interactionAPI::getConnectedContact::",
              contact
            );
            setIsCallConnected(true);
            setInteraction(contact);
          }
        });
      } else {
        console.info(
          "Presolved::interactionAPI::getConnectedContact::No connected Contacts  available"
        );
      }
    });
  };

  const isCCpInitiated = () => {
    let i = 0;
    const PollInterval = setInterval(() => {
      console.log(`Presolved::CCP::Polling to get the login status ${i}`);
      if (connect.agent.initialized) {
        clearInterval(PollInterval);
        listenIncomingActivities();
        console.log(`Presolved::CCP::Login success stoppping the poll`);
        setCcpInitiated(true);
      }
      if (i > 30) {
        clearInterval(PollInterval);
        console.log(`Presolved::CCP::Login failed stoppping the poll`);
      }
      i++;
    }, 1000);
  }; */
