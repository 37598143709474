import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/index";
import { ConfigProvider } from "antd";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: "Montserrat",
      },
    }}
  >
    <App />
  </ConfigProvider>
);
